
import api from "@/api";
import Mixins from "@/Mixins.vue";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import { ISenffProposalComplementaryInfo } from "../interfaces/senffProposal";
import { invoiceAlunoStore, invoiceStore, senffProposalStore } from "../store";
import DateComponent from "@/components/DateComponent.vue";

@Component({
  components: {
    DateComponent,
  },
})
export default class SenffComplementInfo extends Mixins {
  @Prop({ type: Boolean, required: true }) readonly showDialog!: boolean;
  //id da tabela senff_proposal
  @Prop({ type: Number, required: false }) readonly senff_proposal_id!: number;
  @Prop({ type: String, required: false }) readonly proposal_id!: string;
  @Prop({ type: Object, required: true }) readonly invoice_aluno!: object;

  complementInfo = {
    proponentBasicInformations: {
      pratimonyValueRange: null,
      birthDate: null,
      gender: null,
      maritalStatus: null,
      levelEducation: null,
      nationality: null,
      placeBirth: null,
      placeBirthUf: null,
    },
    professionalInformation: {
      role: null,
      income: null,
      companyName: null,
    },
    identificationDocument: {
      type: null,
      dispatchDate: null,
      number: null,
      issuingAgency: null,
      issuingAgencyUf: null,
    },
    filiation: {
      fatherName: null,
      motherName: null,
    },
    spouse: {
      name: null,
      document: null,
    },
    address: {
      neighborhood: null,
      zipCode: null,
      city: null,
      street: null,
      number: null,
      federationUnit: null,
      type: null,
      complement: null,
    },
    creditCardReceipt: {
      invoicePaymentDay: null,
      shippingType: null,
      shippingAddressType: null,
    },
  };

  isOpen: boolean = false;
  isLoading: boolean = false;

  @Watch("showDialog")
  onShowDialogChanged(newValue: boolean) {
    this.isOpen = newValue;
  }

  @Watch("isOpen")
  onInternalDialogChanged(newValue: boolean) {
    this.$emit("update:showDialog", newValue);
  }

  closeDialog() {
    this.isOpen = false;
  }

  @Emit("submitComplementInfo")
  onSubmitComplement(actualStatus: { message: string; status: string }) {
    return actualStatus;
  }

  @Emit("errorComplementInfo")
  onErrorComplement(error: boolean, errorMessage: string) {
    return {
      snackbar: error,
      message: errorMessage,
    };
  }

  get proponentBasicInformationsFields() {
    return [
      {
        name: "pratimonyValueRange",
        type: "select",
        label: "Valor de Patrimonio",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "Renda até 20 mil",
            value: 1,
          },
          {
            text: "Renda maior que 20 mil e menor que 50 mil",
            value: 2,
          },
          {
            text: "Renda maior que 50 mil e menor que 200 mil",
            value: 3,
          },
          {
            text: "Renda maior que 200 mil e menor que 500 mil",
            value: 4,
          },
          {
            text: "Renda maior que 500 mil",
            value: 5,
          },
        ],
      },
      {
        name: "birthDate",
        type: "date",
        label: "Data de nascimento",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
      {
        name: "gender",
        type: "select",
        label: "Genêro",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "Feminino",
            value: "FEMALE",
          },
          {
            text: "Masculino",
            value: "MALE",
          },
          {
            text: "Outros",
            value: "OTHERS",
          },
        ],
      },
      {
        name: "maritalStatus",
        type: "select",
        label: "Estado Civil",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "Casado(a)",
            value: 1,
          },
          {
            text: "Solteiro(a)",
            value: 2,
          },
          {
            text: "Divorciado(a)",
            value: 3,
          },
          {
            text: "Desquitado(a)",
            value: 4,
          },
          {
            text: "Viúvo(a)",
            value: 5,
          },
          {
            text: "Separado(a)",
            value: 6,
          },
          {
            text: "Amasiado(a)",
            value: 10,
          },
          {
            text: "Outros",
            value: 11,
          },
          {
            text: "Não informado",
            value: 13,
          },
        ],
      },
      {
        name: "levelEducation",
        type: "select",
        label: "Nivel de formação",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "Ensino Médio Incompleto",
            value: 2,
          },
          {
            text: "Ensino Médio Completo",
            value: 3,
          },
          {
            text: "Ensino Fundamental Incompleto",
            value: 4,
          },
          {
            text: "Ensino Fundamental Completo",
            value: 5,
          },
          {
            text: "Ensino Superior Completo",
            value: 6,
          },
          {
            text: "Ensino Superior Incompleto",
            value: 7,
          },
          {
            text: "Mestrado",
            value: 8,
          },
          {
            text: "Não Alfabetizado",
            value: 9,
          },
          {
            text: "Não informado",
            value: 10,
          },
          {
            text: "Pós-Graduado",
            value: 11,
          },
          {
            text: "Pós-Graduado 2",
            value: 12,
          },
          {
            text: "Doutorado",
            value: 13,
          },
        ],
      },
      {
        name: "nationality",
        type: "select",
        label: "Nacionalidade",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "Brasileiro",
            value: 10,
          },
          {
            text: "Naturalizado Brasileiro",
            value: 20,
          },
          {
            text: "Outros",
            value: 80,
          },
        ],
      },
      {
        name: "placeBirth",
        type: "text",
        label: "Local de nascimento (Cidade)",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
      {
        name: "placeBirthUf",
        type: "select",
        label: "Local de Nascimento (Estado)",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: this.ufs(),
      },
    ];
  }

  get professionalInformationFields() {
    return [
      {
        name: "role",
        type: "text",
        label: "Profissão",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
      {
        name: "income",
        type: "number",
        label: "Renda",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
      {
        name: "companyName",
        type: "text",
        label: "Nome da empresa (Opcional)",
      },
    ];
  }

  get identificationDocumentFields() {
    return [
      {
        name: "type",
        type: "select",
        label: "Tipo de documento",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "CNH",
            value: 1,
          },
          {
            text: "RG",
            value: 2,
          },
          {
            text: "Passaporte",
            value: 3,
          },
        ],
      },
      {
        name: "dispatchDate",
        type: "date",
        label: "Data de emissão",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
      {
        name: "number",
        type: "text",
        label: "Número do documento",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
      {
        name: "issuingAgency",
        type: "text",
        label: "Órgão emissor do documento (EMISSOR)",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
      {
        name: "issuingAgencyUf",
        type: "select",
        label: "Estado do Órgão emissor do documento",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: this.ufs(),
      },
    ];
  }

  get filiationFields() {
    return [
      {
        name: "fatherName",
        type: "text",
        label: "Nome do pai (Opcional)",
      },
      {
        name: "motherName",
        type: "text",
        label: "Nome do mãe",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
    ];
  }

  get spouseFields() {
    return [
      {
        name: "name",
        type: "text",
        label: "Nome do cônjuge (Opcional)",
      },
      {
        name: "document",
        type: "text",
        label: "Cpf do cônjuge (Opcional)",
      },
    ];
  }

  get addressFields() {
    return [
      {
        name: "neighborhood",
        type: "text",
        label: "Bairro",
        rules: [(v) => !!v || "Campo obrigatório"],
      },
      {
        name: "zipCode",
        type: "text",
        rules: [(v) => !!v || "Campo obrigatório"],
        label: "CEP",
      },
      {
        name: "city",
        type: "text",
        rules: [(v) => !!v || "Campo obrigatório"],
        label: "Cidade",
      },
      {
        name: "street",
        type: "text",
        rules: [(v) => !!v || "Campo obrigatório"],
        label: "Logradouro",
      },
      {
        name: "number",
        type: "text",
        rules: [(v) => !!v || "Campo obrigatório"],
        label: "Número",
      },
      {
        name: "federationUnit",
        type: "text",
        ref: "inputSigla",
        rules: [
          (v) => !!v || "Campo obrigatório",
          (v: string) => (!!v && v.length >= 2) || "Sigla incorreta",
        ],
        label: "Estado (Sigla)",
      },
      {
        name: "type",
        type: "select",
        label: "Tipo do endereço",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "Residencial",
            value: 2,
          },
          {
            text: "Cobrança",
            value: 3,
          },
        ],
      },
      {
        name: "complement",
        type: "text",
        label: "Complemento",
      },
    ];
  }

  get creditCardReceipt() {
    return [
      {
        name: "invoicePaymentDay",
        type: "select",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "1",
            value: 1,
          },
          {
            text: "2",
            value: 2,
          },
          {
            text: "5",
            value: 5,
          },
          {
            text: "7",
            value: 7,
          },
          {
            text: "10",
            value: 10,
          },
          {
            text: "15",
            value: 15,
          },
          {
            text: "20",
            value: 20,
          },
          {
            text: "25",
            value: 25,
          },
        ],
        label: "Dia de vencimento",
      },
      {
        name: "shippingType",
        type: "select",
        label: "Plataforma da fatura",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "Correios",
            value: 1,
          },
          {
            text: "Email",
            value: 2,
          },
          {
            text: "SMS",
            value: 3,
          },
        ],
      },
      {
        name: "shippingAddressType",
        type: "select",
        label: "Tipo de endereço",
        rules: [(v) => !!v || "Campo obrigatório"],
        items: [
          {
            text: "Residencial",
            value: 2,
          },
          {
            text: "Cobrança",
            value: 3,
          },
        ],
      },
    ];
  }

  get formComplementInfo() {
    return [
      {
        step: "proponentBasicInformations",
        title: "Informações Iniciais",
        fields: this.proponentBasicInformationsFields,
      },
      {
        step: "professionalInformation",
        title: "Informações Profissionais",
        fields: this.professionalInformationFields,
      },
      {
        step: "identificationDocument",
        title: "Documentação",
        fields: this.identificationDocumentFields,
      },
      {
        step: "filiation",
        title: "Nome dos país",
        fields: this.filiationFields,
      },
      {
        step: "spouse",
        title: "Informações do cônjuge",
        fields: this.spouseFields,
      },
      {
        step: "address",
        title: "Endereço",
        fields: this.addressFields,
      },
      {
        step: "creditCardReceipt",
        title: "Informações Cartão",
        fields: this.creditCardReceipt,
      },
    ];
  }

  checkStatus(status: string) {
    if (status == "PROCESSING") {
      return {
        status: "Em Análise",
        message:
          "Suas informações foram enviadas, a senff entrará em contato com você via whatsapp para comprovação das informações, após isso você poderá entrar em contato com a escola para solicitação do cartão",
      };
    }

    if (status == "APPROVED" || status == "APPROVE_MANDATORY") {
      return {
        status: "Aprovado",
        message:
          "Seu cadastro foi aprovado, você já pode solicitar o Cartão Senff para sua escola",
      };
    }

    if (status == "PENDING_CARD_LINK") {
      return {
        status: "Pendente de Vinculação",
        message:
          "Seu cadastro foi aprovado, você já pode solicitar o Cartão Senff para sua escola",
      };
    }

    if (status == "DISAPPROVED") {
      return {
        status: "Reprovado",
        message: "Infelizmente seu cadastro para o Cartão Senff foi reprovado",
      };
    }

    if (status == "DERIVATIVE") {
      return {
        status: "Derivado",
        message:
          "Suas informações foram enviadas, porém será necessário uma análise manual delas, assim que a análise for concluída, a senff entrará em contato com você via whatsapp",
      };
    }
  }

  //   validarCidadeUF(value: string) {
  //     // Expressão regular para validar o formato Cidade/UF
  //     const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+\/[A-Z]{2}$/;
  //     return regex.test(value);
  //   }

  validarOrgaoEmissor(value: string) {
    // Expressão regular para validar o formato EMISSOR/UF em maiúsculas
    const regex = /^[A-ZÀ-ÖØ-Þ\s]+\/[A-Z]{2}$/;
    return regex.test(value);
  }

  async submitComplementInfo() {
    let form: any = this.$refs.formComplement;
    if (!form.validate()) {
      return;
    }
    this.isLoading = true;

    let placeBirth = `${this.complementInfo.proponentBasicInformations.placeBirth}/${this.complementInfo.proponentBasicInformations.placeBirthUf}`;
    let issuingAgency = `${this.complementInfo.identificationDocument.issuingAgency.toUpperCase()}/${
      this.complementInfo.identificationDocument.issuingAgencyUf
    }`;

    let payload: ISenffProposalComplementaryInfo = {
      proposalId: this.proposal_id,
      hasResponsibilityStatement: true,
      patrimonyValueRange:
        this.complementInfo.proponentBasicInformations.pratimonyValueRange,
      birthDate: this.complementInfo.proponentBasicInformations.birthDate,
      gender: this.complementInfo.proponentBasicInformations.gender,
      maritalStatus:
        this.complementInfo.proponentBasicInformations.maritalStatus,
      levelEducation:
        this.complementInfo.proponentBasicInformations.levelEducation,
      nationality: this.complementInfo.proponentBasicInformations.nationality,
      placeBirth: "",
      professionalInformation: this.complementInfo.professionalInformation,
      identificationDocument: this.complementInfo.identificationDocument,
      filiation: this.complementInfo.filiation,
      primaryAddress: this.complementInfo.address,
      creditCardReceipt: this.complementInfo.creditCardReceipt,
    };

    payload.placeBirth = placeBirth;
    payload.identificationDocument.issuingAgency = issuingAgency;

    const response = await senffProposalStore.submitSenffComplementInfo({
      proposal: payload,
      senff_proposal_id: this.senff_proposal_id,
    });

    if (response.confirmed) {
      let statusProposal = this.checkStatus(
        response.data.result.proposalStatus
      );
      this.onSubmitComplement(statusProposal);
      this.closeDialog();
    } else {
      console.error(response.data);
      let snackbar = true;
      let textSnack = "";
      for (let error in response.data) {
        for (let messageError of response.data[error]) {
          textSnack += messageError + "\n";
        }
      }
      this.onErrorComplement(snackbar, textSnack);
    }
    this.isLoading = false;
  }

  async mounted() {
    const citiesResponse = await api.getCities();
    this.complementInfo.address = {
      city: null,
      neighborhood: this.invoiceAluno.neighborhood,
      number: this.invoiceAluno.street_number,
      zipCode: this.invoiceAluno.cep,
      street: this.invoiceAluno.street,
      complement: this.invoiceAluno.street_complement,
      federationUnit: null,
      type: null,
    };

    if (citiesResponse.data) {
      let cidadeAluno = citiesResponse.data.find(
        (c) => c.codigo_municipio_completo == this.invoiceAluno.ibge
      );
      if (cidadeAluno) {
        this.complementInfo.address.city = cidadeAluno.nome_municipio;
        this.complementInfo.address.federationUnit = cidadeAluno.nome_uf;
      }
    }
  }
}
